<template>
  <el-dialog
    id="EditUserProfileDialog"
    :title="title"
    :visible.sync="dialogShow"
    center
    modal
    top="5vh"
    :close-on-click-modal="false"
    width="596px"
    close-on-press-escape
    class="my-dialog abow_dialog"
    @opened="onShow"
    destroy-on-close
    @keydown.enter.native="onCommit"
  >
    <el-form ref="EditUserProfileForm" :model="model" :rules="rules" @submit.native.prevent="onSubmit" v-loading="loading">
      <el-row v-if="loading" justify="center" align="middle" type="flex" style="height:640px">
        <img class="svg" :src="require('../../../assets/icons/no_data.svg')" />
      </el-row>
      <el-row v-else>
        <el-tabs v-model="activeTab">
          <el-tab-pane :label="$t('Загальні налаштування')" name="common"></el-tab-pane>
          <el-tab-pane :label="$t('Додаткові налаштування')" name="additional"></el-tab-pane>
          <el-tab-pane :label="$t('Налаштування інтерфейсу')" name="interface"></el-tab-pane>
        </el-tabs>
        <div v-if="activeTab == 'common'">
          <el-form-item prop="login" :label="$t('Логін (e-mail)')">
            <el-input v-model="model.login" autocomplete="off" readonly></el-input>
          </el-form-item>
          <el-button type="success" @click="onChangePassword" class="apply-button button-in-form">{{ $t("Змінити пароль") }}</el-button>
          <el-row justify="space-between" type="flex">
            <el-col :span="7">
              <el-form-item prop="first_name" :label="$t('Ім`я')">
                <el-input v-model="model.first_name" style="width:100%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item prop="last_name" :label="$t('Прізвище')">
                <el-input v-model="model.last_name" style="width:100%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item prop="middle_name" :label="$t('По-батькові')">
                <el-input v-model="model.middle_name" :placeholder="$t('Не обрано')" style="width:100%"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row justify="space-between" type="flex">
            <el-col :span="7">
              <el-form-item prop="phone" :label="$t('Телефон')">
                <el-input v-mask="'+38(099)999-99-99'" placeholder="+38(___)___-__-__" v-model="model.phone" style="width:100%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item prop="skype" label="Skype">
                <el-input v-model="model.skype" :placeholder="$t('Не обрано')" style="width:100%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item prop="telegram" label="Telegram">
                <el-input v-model="model.telegram" :placeholder="$t('Не обрано')" style="width:100%"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item prop="post" :label="$t('Посада')">
            <el-input v-model="model.post" :placeholder="$t('Не обрано')" autocomplete="off"></el-input>
          </el-form-item>
          <el-row justify="space-between" type="flex">
            <el-col :span="11">
              <el-form-item prop="district" :label="$t('Область')">
                <el-input v-model="model.district" :placeholder="$t('Не обрано')" style="width:100%" readonly></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item prop="city" :label="$t('Населений пункт')">
                <el-input v-model="model.city" :placeholder="$t('Не обрано')" style="width:100%" readonly></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item prop="notes" :label="$t('Примітка')">
            <el-input type="textarea" autosize :placeholder="$t('Не обрано')" v-model="model.notes"></el-input>
          </el-form-item>
        </div>
        <div v-if="activeTab == 'additional'">
          <el-form-item prop="defaultCatalogLang" :label="$t('Каталог за замовченням')">
            <el-select style="width:100%" v-model="userSettings.defaultCatalogLang">
              <el-option v-for="item in selectOptions.defaultCatalogLang" :key="item.value" :label="$t(item.label)" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="filterContractsByDate" :label="$t('Фільтрувати договори за датою')">
            <el-select style="width:100%" v-model="userSettings.filterContractsByDate">
              <el-option v-for="item in selectOptions.filterContractsByDate" :key="item.value" :label="$t(item.label)" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="defaultCustomer" :label="$t('Замовник за замовченням')">
            <el-input
              v-model="customerComputed"
              autocomplete="off"
              :suffix-icon="customerComputedIcon"
              @mousedown.native="findOrg($event, 'customer')"
              readonly
              :placeholder="$t('Не обрано')"
            ></el-input>
          </el-form-item>
          <el-form-item prop="defaultExecutor" :label="$t('Виконавець за замовченням')">
            <el-input
              v-model="executorComputed"
              autocomplete="off"
              :suffix-icon="executorComputedIcon"
              @mousedown.native="findOrg($event, 'executor')"
              readonly
              :placeholder="$t('Не обрано')"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item prop="floatingFilter" :label="$t('Плаваючий фільтр')">
            <el-select style="width:100%" v-model="userSettings.floatingFilter">
              <el-option v-for="item in selectOptions.onOff" :key="item.value" :label="$t(item.label)" :value="item.value"> </el-option>
            </el-select>
          </el-form-item> -->
        </div>
        <div v-if="activeTab == 'interface'">
          <el-row>
            <el-button type="success" @click="onExportSettingsToJSON" class=" button-in-form">{{ $t("Експортувати налаштування в JSON") }}</el-button>
          </el-row>
          <el-row>
            <el-button type="info" @click="onImportSettingsFromJSON" class=" button-in-form">{{ $t("Імпортувати налаштування із JSON") }}</el-button>
          </el-row>
          <el-row>
            <el-button @click="onResetUserSettings" class=" button-in-form">{{ $t("Зкинути по замовчанню") }}</el-button>
          </el-row>
        </div>
      </el-row>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button native-type="submit" type="success" @click="onCommit" class="apply-button">{{ $t("Застосувати") }}</el-button>
      <el-button type="text" @click="dialogShow = false" class="cancel-button">{{ $t("Відмінити") }}</el-button>
    </span>
    <ChangePassword @commit="passwordCommitChanges" />
    <FindOrg @find-commit="onFindOrgCommit" bus-event="forms.orgs.profile.find" />
    <SelectFile @commit="importSettingsData" bus-event="user.settings.forms.select.file" />
  </el-dialog>
</template>

<script>
import { bus } from "@/main";
import { _ } from "vue-underscore";

export default {
  name: "EditUserProfile",
  components: {
    ChangePassword: () => import("@/components/forms/ChangePassword"),
    FindOrg: () => import("@/components/forms/FindOrg"),
    SelectFile: () => import("@/components/forms/SelectFile"),
  },
  data() {
    var that = this;
    var defaultValidateRule = { required: true, message: "Це поле є необхідним" };
    return {
      loading: false,
      dialogShow: false,
      activeTab: "common",
      title: "Мій профіль",
      operation: null,
      model: {},
      initModel: {},
      initUserSettings: {},
      userSettings: {},
      rules: {
        first_name: [defaultValidateRule],
        last_surname: [defaultValidateRule],
      },
    };
  },

  methods: {
    onExportSettingsToJSON() {
      var state = this.$store.getters["main/userSettingsTotal"]();
      this.saveDataToJSON({ dataType: "userSettings", data: state }, "user_settings_" + new Date().toLocaleDateString() + ".json");
    },
    onImportSettingsFromJSON() {
      bus.$emit("user.settings.forms.select.file.show", {
        title: $tt("Імпорт налаштувань"),
        operation: "settings.import",
        initModel: {},
      });
    },
    importSettingsData(data) {
      if (data) {
        this.readJSONFile(data.file)
          .then((result) => {
            if (result && result.dataType && result.dataType == "userSettings" && result.data) {
              this.$store.dispatch("main/updateUserSettingsGlobal", result.data).catch((err) => {
                globalErrorMessage($tt("Помилка застосування"), err.data.msg);
              });
            } else {
              globalErrorMessage($tt("Помилка імпорту"), $tt("Помилка в структурі JSON файлу"));
            }
          })
          .catch((err) => {
            globalErrorMessage($tt("Помилка імпорту"), err);
          });
      }
    },
    onResetUserSettings() {
      this.$confirm($tt("Ви дійсно бажаєте зкинутуи налаштування?"), $tt("Зкидання налаштувань"), {
        confirmButtonText: $tt("Так"),
        cancelButtonText: $tt("Відміна"),
        type: "warning",
      })
        .then(() => {
          this.$store.dispatch("main/resetUserSettingsGlobal").catch((err) => {
            globalErrorMessage($tt("Помилка застосування"), err.data.msg);
          });
        })
        .catch((err) => {});
    },
    onCommit() {
      this.$refs.EditUserProfileForm.validate((valid) => {
        if (valid) {
          this.$emit("commit", { model: this.model, userSettings: this.userSettings, operation: "update" });
        } else {
          this.$message.error($tt("Перевірте введені дані!"));
        }
        return false;
      });
    },
    onShow() {
      this.$refs.EditUserProfileForm.resetFields();
      this.model = _.extend({}, this.initModel);
      this.userSettings = _.extend({}, this.initUserSettings);
      this.loading = false;
    },
    onChangePassword() {
      bus.$emit("forms.password.change.show", { title: "Зміна пароля" });
    },
    passwordCommitChanges(data) {
      bus.$emit("forms.password.change.hide");
    },
    findOrg(event, id) {
      switch (id) {
        case "customer": {
          if (this.customerComputed) {
            this.customerComputed = null;
          } else {
            bus.$emit("forms.orgs.profile.find.show", {
              target: "customer",
              title: $tt("Пошук організації замовника"),
              singleRecord: true,
              allowEmpty: true,
            });
          }
          break;
        }
        case "executor": {
          if (this.executorComputed) {
            this.executorComputed = null;
          } else {
            bus.$emit("forms.orgs.profile.find.show", {
              target: "executor",
              title: $tt("Пошук організації виконавця"),
              singleRecord: true,
              allowEmpty: true,
            });
          }
          break;
        }
        default:
          break;
      }
    },
    onFindOrgCommit(data) {
      switch (data.target) {
        case "customer": {
          this.$set(this.userSettings, "defaultCustomer", data.result ? data.result[0] : data.result);
          break;
        }
        case "executor": {
          this.$set(this.userSettings, "defaultExecutor", data.result ? data.result[0] : data.result);
          break;
        }
        default:
          break;
      }
    },
  },
  created() {
    bus.$on("forms.profile.edit.show", (data) => {
      this.loading = true;
      this.title = (data && data.title) || this.title;
      this.initModel = _.extend({}, data ? data.initModel : {});
      this.initUserSettings = _.extend({}, data ? data.userSettings : {});
      this.dialogShow = true;
    });
    bus.$on("forms.profile.edit.hide", () => {
      this.dialogShow = false;
    });
  },
  computed: {
    selectOptions() {
      return this.$store.getters["main/selectionOptions"].userProfile;
    },
    customerComputed: {
      get() {
        if (!this.userSettings.defaultCustomer || !this.userSettings.defaultCustomer.axiomaId) return null;
        else return this.userSettings.defaultCustomer.name;
      },
      set(value) {
        this.userSettings.defaultCustomer = value;
      },
    },
    customerComputedIcon() {
      if (this.customerComputed) return "el-icon-close";
      else return "el-icon-more";
    },
    executorComputed: {
      get() {
        if (!this.userSettings.defaultExecutor || !this.userSettings.defaultExecutor.axiomaId) return null;
        else return this.userSettings.defaultExecutor.name;
      },
      set(value) {
        this.userSettings.defaultExecutor = value;
      },
    },
    executorComputedIcon() {
      if (this.executorComputed) return "el-icon-close";
      else return "el-icon-more";
    },
  },
};
</script>
<style lang="scss">
#EditUserProfileDialog {
  .el-dialog {
    max-height: 740px !important;
  }
  .button-in-form {
    margin-bottom: 16px;
    width: 300px;
  }
}
</style>
